import React, { FocusEventHandler, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { ContinueWithGoogle } from '../../../components/ContinueWithGoogle'
import { CheckBox } from '../../../components/FormElements/Checkbox'
import { Input } from '../../../components/FormElements/Input'
import { Separator } from '../../../components/Separator'
import DayJS from '../../../helpers/DayJS'
import { decodeSearchParams, persistUrlParameters } from '../../../helpers/urls'
import { isEmailValid, testPasswordStrength } from '../../../helpers/validators'
import { useCreateUser } from '../../../hooks/queries/enrollment/useCreateUser'
import { useUser } from '../../../hooks/queries/useUser'
import { isProduction } from '../../../interfaces/constants'
import LoadingPage from '../../static/LoadingPage'
import { PostCreateUser } from '../api-register'

export const SignUpStep1 = () => {
    const { t, i18n } = useTranslation('signUp')
    const { user } = useUser()
    const { create, migrate, isPending: isLoading, isError } = useCreateUser()
    const [searchParams] = useSearchParams()
    const queryParams =
        decodeSearchParams<Partial<PostCreateUser & { redirect: string }>>(searchParams)
    const [changes, setChanges] = useState({
        ...queryParams,
        termsAndConditions: false,
        email: '',
        password: '',
    })

    const userLang = i18n.resolvedLanguage ?? 'en'

    const isMigration = !!user?.requireMigration

    useEffect(() => {
        persistUrlParameters()
    }, [])

    useEffect(() => {
        if (!user?.email) return
        setChanges(prev => ({ ...prev, email: user.email ?? '' }))
    }, [user?.email])

    const [passwordError, setPasswordError] = useState<string>()
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')

    const { email, password, termsAndConditions } = changes

    const onChange: FocusEventHandler<HTMLInputElement> = ({ target: { name, value } }) =>
        setChanges(prev => ({ ...prev, [name]: value }))

    const confirmPasswordError =
        password !== passwordConfirm ? t('Passwords do not match.') : undefined

    const isCompleted =
        !!email &&
        isEmailValid(email) &&
        !!password &&
        password === passwordConfirm &&
        !passwordError &&
        changes.termsAndConditions

    const { redirect, ...data } = changes

    const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        if (!isCompleted) return
        event?.preventDefault()
        isMigration ? migrate(data) : create(data)
    }

    if (isLoading) return <LoadingPage />

    return (
        <SignUpStyle>
            <strong className="blue">
                {t('Step {{ step }} of {{ steps }}', {
                    step: 1,
                    steps: 2,
                })}
            </strong>
            <h1 className="title">{t(isMigration ? 'Update account' : 'Create account')}</h1>
            <p className="light">
                {t(
                    isMigration
                        ? 'Leave us your information and become part of the community of students and professionals who are evolving thanks to cooperation.'
                        : 'Register on our platform and join the community of students and professionals who are already boosting their digital profile thanks to cooperation.'
                )}{' '}
            </p>
            <br />
            <ContinueWithGoogle />
            <form onSubmit={onSubmit}>
                <Input
                    {...{
                        label: t('Email'),
                        id: 'email',
                        name: 'email',
                        type: 'email',
                        placeholder: user?.email ?? 'username@mail.com',
                        autoComplete: 'username',
                        value: user?.email ?? email,
                        disabled: isMigration,
                        onChange,
                    }}
                />
                <br />
                <Input
                    {...{
                        label: t('Password'),
                        id: 'password',
                        name: 'password',
                        type: 'password',
                        autoComplete: 'new-password',
                        value: password,
                        onChange,
                        onBlur: ({ target: { value } }) => {
                            const isSecure = testPasswordStrength(value, { special: false })
                            value && isSecure !== true
                                ? setPasswordError(t(isSecure))
                                : setPasswordError(undefined)
                        },
                        error: passwordError,
                    }}
                />
                <br />
                <Input
                    {...{
                        label: t('Password confirmation'),
                        id: 'confirm_password',
                        name: 'confirm_password',
                        type: 'password',
                        autoComplete: 'new-password',
                        value: passwordConfirm,
                        onChange: ({ target: { value } }) => setPasswordConfirm(value),
                        error: confirmPasswordError,
                        onDoubleClick: () => {
                            if (!isProduction) {
                                const fakeUser = randomFakeUser()
                                setChanges({ ...changes, ...fakeUser })
                                setPasswordConfirm(fakeUser?.password ?? '')
                            }
                        },
                    }}
                />
                <br />
                <div style={{ display: 'flex', gap: 20, fontSize: 12 }}>
                    <CheckBox
                        name="termsAndConditions"
                        id="termsAndConditions"
                        style={{
                            width: '1.5em',
                        }}
                        checked={termsAndConditions}
                        onChange={({ target: { checked } }) =>
                            setChanges({ ...changes, termsAndConditions: checked })
                        }
                    />
                    <span>
                        <Trans
                            t={t}
                            i18nKey={
                                'I accept the Privacy Policy and Terms and Conditions. I want to be part of this community!.'
                            }
                        >
                            I accept the{' '}
                            <a
                                href="https://egg.live/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                            and{' '}
                            <a
                                href="https://egg.live/terms-and-conditions"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms and Conditions
                            </a>
                            . I want to be part of this community!.
                        </Trans>
                    </span>
                </div>
                {isError && (
                    <p style={{ color: '#ff647c', textAlign: 'center' }}>
                        {t(
                            'It is not possible to continue with the registration at the moment, please try again later.'
                        )}
                    </p>
                )}
                <br />
                <button
                    className="primary"
                    children={t('Continue')}
                    style={{ width: '100%' }}
                    disabled={!isCompleted}
                />
            </form>
        </SignUpStyle>
    )
}

const randomFakeUser = (): Partial<PostCreateUser> => {
    const ext = '@mail.com'
    const time = DayJS().format('YYYYMMDD_HHmmss')
    const email = `${time}${ext}`
    const termsAndConditions = true
    const password = '#@VYNtqDguFe8Wb'
    navigator?.clipboard?.writeText(password)
    navigator?.clipboard?.writeText(email)
    return { email, password, termsAndConditions }
}

export const SignUpStyle = styled.div`
    & button {
        width: 100%;
        width: -webkit-fill-available;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
            width: 100% !important;
        }
    }
`
