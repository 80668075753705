import './styles/global.scss'
import './styles/redesign.scss'
import './library.js'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AppRoutes } from './router/AppRoutes'
import DayJS from 'dayjs'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Interceptor from './helpers/interceptors'
import ReactGA from 'react-ga4'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from 'styled-components'
import UIContextProvider from './context/UIContext'
import axios from 'axios'
import { defaultTheme } from './styles/themes/defaultTheme'
import duration from 'dayjs/plugin/duration'
import { isProduction } from './interfaces/constants'
import { isEuropeanSite, persistUrlParameters } from './helpers/urls'
import { reCaptchaOptions } from './infrastructure/reCaptcha'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Suspense, useEffect } from 'react'
import useNotification from './hooks/useNotificationApi'
import LoadingPage from './pages/static/LoadingPage'
import { useIntercom } from './hooks/useIntercom'
import { SocketProvider } from './context/SocketContext'

DayJS.extend(relativeTime)
DayJS.extend(duration)

Interceptor.addInterceptors(axios)

const { REACT_APP_GOOGLE_ANALYTICS } = process.env

const App = () => {
    const { clientBoot } = useIntercom()
    const { showNotification, getPermissionNotification } = useNotification()

    useEffect(() => {
        persistUrlParameters()
        getPermissionNotification()

        const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
            if (typeof ev.data !== 'object') return
            if (!ev.data.type) return
            if (ev.data.type !== 'button-click') return
            if (!ev.data.message) return

            showNotification(
                'Solicitud de ayuda',
                'Un equipo tiene dudas y estamos seguro que podrás con ello. Acéptala para como comenzar tu Mentoría.'
            )
        }
        window.addEventListener('message', handler)

        // Boot analytics
        if (REACT_APP_GOOGLE_ANALYTICS && !isEuropeanSite)
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS)
        else console.warn('Google Analytics is not available in this site')

        return () => window.removeEventListener('message', handler)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Boot intercom
        clientBoot({
            app_id: isProduction ? 'qu6m3nwu' : 'cw1s2grp',
            action_color: '#FFCD00',
            background_color: '#37474F',
            vertical_padding: 0,
            horizontal_padding: 100,
            alignment: 'left',
            hide_default_launcher: true,
        })
    }, [clientBoot])

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 60 * 5,
                retry: 2,
                refetchOnWindowFocus: process.env.NODE_ENV === 'production',
            },
        },
    })

    return (
        <Suspense fallback={<LoadingPage />}>
            <QueryClientProvider client={queryClient}>
                <GoogleReCaptchaProvider {...reCaptchaOptions}>
                    <ThemeProvider theme={defaultTheme}>
                        <UIContextProvider>
                            <SocketProvider>
                                <AppRoutes />
                            </SocketProvider>
                        </UIContextProvider>
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </GoogleReCaptchaProvider>
            </QueryClientProvider>
        </Suspense>
    )
}

export default App
