import styled from 'styled-components'
import bg_gradient from '../assets/bg_gradient.jpg'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { Outlet } from 'react-router-dom'
import { LanguageSelector } from '../components/LanguageSelector'

export const LoginLayout = () => {
    return (
        <StyleContainer className="redesign">
            <main>
                <div className="main-container">
                    <Outlet />
                </div>
            </main>
            <footer>
                <LanguageSelector label="" />
            </footer>
        </StyleContainer>
    )
}

const StyleContainer = styled.div`
    display: grid;
    place-self: center;
    background: url('${bg_gradient}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    height: 100dvh;
    width: 100dvw;
    .center {
        text-align: center;
    }

    > main {
        width: 326px;
        margin: auto;
        .main-container {
            background-color: ${defaultTheme.color.white};
            padding: 30px;
            border-radius: 20px;

            h1.title {
                font-size: 18px;
                text-align: center;
                margin-top: 1em;
                margin-bottom: 2em;
            }

            button {
                box-sizing: border-box;
                width: 100%;
                min-height: 55px;
            }
        }
    }

    footer {
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
        justify-content: end;

        & label,
        & label select {
            border: 0;
            border-radius: 0;
            background-color: transparent;
            text-align: center;
            opacity: 0.9;
        }

        & label select,
        & label select:focus-within {
            appearance: auto;
            background-image: none;
            border: 0;
            box-shadow: none;
        }
    }

    footer {
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
        justify-content: end;

        & label,
        & label select {
            border: 0;
            border-radius: 0;
            background-color: transparent;
            text-align: center;
            opacity: 0.9;
        }

        & label select,
        & label select:focus-within {
            appearance: auto;
            background-image: none;
            border: 0;
            box-shadow: none;
        }
    }
`
