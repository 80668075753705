import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import IntercomIcon from '../../../../assets/intercom_btn.png'
import { ModalType, useUIContext } from '../../../../context/UIContext'
import { useUser } from '../../../../hooks/queries/useUser'
import { useIntercom } from '../../../../hooks/useIntercom'
import { useToken } from '../../../../hooks/useToken'
import AvatarEgg from '../../../Misc/AvatarEgg'

const eggLogo = require('../../../../assets/egg_no_shadow.svg').default

export const useNavBarItems = () => {
    const { t } = useTranslation('navbar')
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { _classId: _courseId } = useToken()
    const { Client, openChat } = useIntercom()
    const { dispatchModal } = useUIContext()
    const { user, displayNameOrName } = useUser()
    const [activeItem, setActiveItem] = useState<ItemMenuProps>()
    const [visibleMenu, setVisibleMenu] = useState(false)
    const [visibleIndexContent, setVisibleIndexContent] = useState(false)

    const states = useMemo(
        () => ({
            activeItem,
            setActiveItem,
            visibleMenu,
            setVisibleMenu,
            visibleIndexContent,
            setVisibleIndexContent,
        }),
        [activeItem, visibleMenu, visibleIndexContent]
    )

    const isActive = useCallback(
        (label: string, url?: string) =>
            activeItem?.label === label || (!!url && !!pathname.includes(url)),
        [activeItem, pathname]
    )

    const isInCourse = pathname.includes('/content') || pathname.includes('/dashboard')

    const topMenu: MenuProps[] = [
        {
            logo: eggLogo,
            pageName: t('Home'),
            url: '/home',
        },
        {
            icon: ['fal', 'bolt'],
            activeIcon: ['fas', 'bolt'],
            pageName: 'Ranking',
            url: '/ranking',
            onClick: () => (!isInCourse ? navigate('/ranking') : navigate(`/ranking/${_courseId}`)),
        },
    ]

    if (user?.hideMarketplace !== true)
        topMenu.push({
            icon: ['fal', 'chart-network'],
            activeIcon: ['fas', 'chart-network'],
            pageName: t('Ecosystem', {
                ns: 'ecosystem',
            }),
            url: '/ecosystem',
        })

    const bottomMenu: MenuProps[] = [
        {
            activeIcon: ['fal', 'users'],
            icon: ['fal', 'users'],
            pageName: t('My profile'),
            url: '/user-profile',
            activeSvgIcon: (
                <AvatarEgg size={{ mobile: 25, desktop: 30 }} title={displayNameOrName} />
            ),
            svgIcon: <AvatarEgg size={{ mobile: 25, desktop: 30 }} title={displayNameOrName} />,
        },
        {
            activeIcon: ['fas', 'message-question'],
            icon: ['far', 'message-question'],
            pageName: t('Help Center'),
            svgIcon: <img src={IntercomIcon} width={20} alt="Intercom Button" />,
            onClick: openChat,
            hidden: !Client,
            neverActiveMobile: true,
        },
        {
            activeIcon: ['fal', 'arrow-right-from-bracket'],
            icon: ['fal', 'arrow-right-from-bracket'],
            pageName: t('Log out'),
            onClick: () => dispatchModal({ type: ModalType.CLOSE_SESSION }),
            neverActiveMobile: true,
        },
    ]

    const menuItems = (menu: MenuProps[]): ItemMenuProps[] =>
        menu.map(({ pageName, url, onClick, ...rest }) => {
            return {
                label: pageName,
                onClick: onClick ?? (url ? () => navigate(url) : undefined),
                isActive: isActive(pageName, url),
                url,
                ...rest,
            }
        })

    useEffect(() => {
        const allMenuItems = [
            ...menuItems(topMenu),
            ...menuItems(bottomMenu),
            // ...menuItems(courseMenu),
        ]
        allMenuItems.forEach(item => {
            if (item.url?.includes(pathname)) {
                setActiveItem(item)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return {
        topMenuItems: menuItems(topMenu),
        bottomMenuItems: menuItems(bottomMenu),
        // courseMenuItems: isInCourse ? menuItems(courseMenu) : [],
        // courseItem: isInCourse ? menuItems(courseMenu)[1] : undefined,
        states,
    }
}

export default useNavBarItems

interface MenuBaseProps {
    logo?: string
    activeIcon?: IconProp
    icon?: IconProp
    children?: ReactNode
    hidden?: boolean
    svgIcon?: ReactNode
    activeSvgIcon?: ReactNode
    onClick?: () => void
    neverActiveMobile?: boolean
    url?: string
}

export type ItemMenuProps = MenuBaseProps & {
    label: string
    isActive: boolean
}

type MenuProps = MenuBaseProps & {
    pageName: string
}
