import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Input } from '../../../../components/FormElements/Input'
import { useIntercom } from '../../../../hooks/useIntercom'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { resetPassword } from '../../../../infrastructure/api-activation'
import { useMutation } from '@tanstack/react-query'

export const ResetWithEmail = ({ email }: { email?: string }) => {
    const { t } = useTranslation('signIn')
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { clientUpdate, chatWithSupport } = useIntercom()
    const [sended, setSended] = useState(false)
    const navigate = useNavigate()
    const [error, setError] = useState<string>()

    const { mutate, isPending } = useMutation({
        mutationFn: resetPassword,
        onSuccess: () => setSended(true),
        onError: error =>
            setError(t((error as any)?.response?.data?.message || 'Unable to reset password')),
    })

    const reset = async () => {
        if (!executeRecaptcha) return
        if (!email) return setError(t('Email is required'))
        const captcha_token = await executeRecaptcha('signInWithCredentials')
        mutate({ email, captcha_token })
    }

    const openIntercom = () => {
        clientUpdate({ email, language_override: 'es' })
        chatWithSupport(t('Hi, I need help resetting my password!'))
    }

    if (sended)
        return (
            <>
                <section style={{ textAlign: 'center' }}>
                    <h1 className="title">{t('Restore password')}</h1>
                    <p className="light">
                        {t(
                            'We already sent an email with the instructions for resetting the password.'
                        )}
                    </p>
                    <h4>
                        <Trans
                            i18nKey="If you are having trouble following the instructions or have not received the email, please ask our <0>technical support</0> for help."
                            t={t}
                            components={[
                                <Link to="" onClick={openIntercom} children="technical support" />,
                            ]}
                        />
                    </h4>
                </section>
                <br />
                <br />
                <button
                    type="submit"
                    className="primary"
                    children={t('Log in')}
                    onClick={() => navigate('/login')}
                />
            </>
        )

    return (
        <>
            <section>
                <h1 className="title">{t('Restore password')}</h1>
                <p className="light center">
                    {t(
                        'We will send an email to your email box with the necessary instructions to reset your password.'
                    )}
                </p>
                <br />
                <Input
                    {...{
                        id: 'email',
                        name: 'email',
                        type: 'email',
                        defaultValue: email,
                        disabled: true,
                        label: t('Email'),
                        error,
                    }}
                />
            </section>
            <br />

            <div className="buttons column">
                <button
                    type="submit"
                    className="primary"
                    children={t('Restore password')}
                    onClick={reset}
                />

                <button
                    className="text"
                    children={t(`Cancel`)}
                    onClick={() => navigate('/login')}
                    disabled={isPending}
                />
            </div>
        </>
    )
}
