import { Certificate, useCertificates } from '../../hooks/useCertificates'
import { useEffect, useState } from 'react'
import CertificateItem from './CertificateItem'
import { ResponsiveLayout } from '../../layouts/ResponsiveLayout'
import { TitleBar } from '../../components/NavBar/TitleBar'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Certificates = () => {
    const { t } = useTranslation('certificates')
    const { getCertificatesList } = useCertificates()
    const [certificatesList, setCertificatesList] = useState<Certificate[]>([])

    useEffect(() => {
        getCertificatesList().then(setCertificatesList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ResponsiveLayout
            header={null}
            content={
                <CertificatesPage>
                    <TitleBar pageTitle={t('Certificates')} />
                    <CertificatesBlock>
                        {certificatesList.length > 0 && (
                            <div className="table">
                                {certificatesList.map(certificate => (
                                    <CertificateItem key={certificate._id} {...certificate} />
                                ))}
                            </div>
                        )}
                    </CertificatesBlock>
                </CertificatesPage>
            }
            aside={null}
            footer={null}
        />
    )
}

export default Certificates

const CertificatesBlock = styled.div`
    .table {
        background-color: white;
        border-radius: 12px;
        padding: 20px;
        padding-bottom: 5px;
    }
    margin-bottom: 30px;
`

const CertificatesPage = styled.div`
    .heading {
        h2 {
            font-size: 22px;
            font-weight: 500;
        }
        p.lead {
            font-size: 16px;
            color: ${props => props.theme.color.primary};
        }
    }
    .title {
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0 30px;
    }
`
